// ** React Imports
import {Fragment, useState} from 'react'
import alertConfirm from 'react-alert-confirm';

// ** Custom Components

// ** Third Party Components
import {X} from 'react-feather'
import toast from 'react-hot-toast'
import Flatpickr from 'react-flatpickr'
import Select, {components} from 'react-select' // eslint-disable-line
import PerfectScrollbar from 'react-perfect-scrollbar'
import {useForm, Controller} from 'react-hook-form'

// ** Reactstrap Imports
import {Button, Modal, ModalHeader, ModalBody, Label, Input, Form} from 'reactstrap'

// ** Utils
import {selectThemeColors, isObjEmpty} from '.././../utils'


// ** Styles Imports

import '../../scss/react/libs/react-select/_react-select.scss'
import '../../scss/react/libs/flatpickr/flatpickr.scss'

alertConfirm.config({
  lang: 'en',
  zIndex: 10000024,
  okText: 'NO',
  cancelText: 'YES',
});
const AddEventSidebar = props => {
  // ** Props
  const {
    open,
    store,
    dispatch,
    addEvent,
    calendarApi,
    isAll,
    selectEvent,
    updateEvent,
    removeEvent,
    refetchEvents,
    calendarsColor,
    handleAddEventSidebar,
    employee,
    employeeName,
    company
  } = props

  // ** Vars & Hooks
  const selectedEvent = store.selectedEvent,
    {
      control,
      setError,
      setValue,
      getValues,
      handleSubmit,
      formState: {errors}
    } = useForm({
      defaultValues: {title: '', employeeName}
    })

  // ** States
  const [url, setUrl] = useState('')
  const [description, setDescription] = useState('')
  const [allDay, setAllDay] = useState(false)
  const [location, setLocation] = useState('')
  const [_id, set_id] = useState(null)
  //add 30 minutes to start time
  const [endPicker, setEndPicker] = useState(new Date(new Date().getTime() + 10 * 60000))
  const [startPicker, setStartPicker] = useState(new Date())
  const [calendarLabel, setCalendarLabel] = useState([{value: 'callLog', label: 'Call Log', color: 'warning'},])

  // ** Select Options.


  // switch value and label
  const options = [
    {value: 'callLog', label: 'Call Log', color: 'warning'},
    {value: 'PO', label: 'PO', color: 'danger'},
    {value: 'SO', label: 'SO', color: 'success'},
    {value: 'companyExpiry', label: 'Company Expiry', color: 'info'},
    {value: 'HR', label: 'HR', color: 'secondary'}

  ]


  // ** Custom select components
  const OptionComponent = ({data, ...props}) => {
    return (
      <components.Option {...props}>
        <span className={`bullet bullet-${data.color} bullet-sm me-50`}></span>
        {data.label}
      </components.Option>
    )
  }


  // ** Adds New Event
  const handleAddEvent = () => {

    const obj = {
      title: getValues('title'),
      start:startPicker,
      end: endPicker,
      employeeName: employeeName,
      allDay,
      display: 'block',
      extendedProps: {
        calendar: calendarLabel[0].value,
        url: url.length ? url : undefined,
        location: location.length ? location : undefined,
        description: description.length ? description : undefined
      },

      company: company,
      employee: employee

    }

    dispatch(addEvent(obj))
    refetchEvents()
    handleAddEventSidebar()
    toast.success('Event Added')
  }

  // ** Reset Input Values on Close
  const handleResetInputValues = () => {
    dispatch(selectEvent({}))
    setValue('title', '')
    setAllDay(false)
    setUrl('')
    setLocation('')
    set_id(null)
    setDescription('')
    set_id(null)
    setCalendarLabel([{value: 'callLog', label: 'Call Log', color: 'warning'},])
    setStartPicker(new Date())
    setEndPicker(new Date(new Date().getTime() + 10 * 60000))
  }

  // ** Set sidebar fields
  const handleSelectedEvent = () => {
    if (!isObjEmpty(selectedEvent)) {
      const calendar = selectedEvent.extendedProps.calendar

      const resolveLabel = () => {
        if (calendar.length) {
          return {label: calendar, value: calendar, color: calendarsColor[calendar]}
        } else {
          return {value: 'callLog', label: 'Call Log', color: 'warning'}
        }
      }
      setValue('title', selectedEvent.title || getValues('title'))
      setAllDay(selectedEvent.allDay || allDay)
      setUrl(selectedEvent.url || url)
      setLocation(selectedEvent.extendedProps.location || location)
      setDescription(selectedEvent.extendedProps.description || description)
      setStartPicker(new Date(selectedEvent.start))
      setEndPicker(selectedEvent.allDay ? new Date(selectedEvent.start) : new Date(new Date(selectedEvent.start).getTime() + 15 * 60 * 1000))
      setCalendarLabel([resolveLabel()])

      set_id(selectedEvent.extendedProps._id)
      setValue('employeeName', selectedEvent.extendedProps.employeeName)
    }
  }


  // ** Updates Event in Store
  const handleUpdateEvent = () => {
    if (getValues('title').length) {
      const eventToUpdate = {
        id: selectedEvent.id,
        title: getValues('title'),
        allDay,
        start: startPicker,
        end: endPicker,
        url,
        display: allDay === false ? 'block' : undefined,
        extendedProps: {
          location,
          description: description,
          calendar: calendarLabel[0].value
        },
        company: company,
        employee: employee
      }

      dispatch(updateEvent({...eventToUpdate, _id, company, employee}))

      handleAddEventSidebar()
      toast.success('Event Updated')
    } else {
      setError('title', {
        type: 'manual'
      })
    }
  }


  const handleDeleteEvent = () => {
    alertConfirm({
      title: 'Confirmation',
      content: 'Are you sure you want to delete this event?',
      onCancel: () => {
        dispatch(removeEvent({_id, company, employee}))
        handleAddEventSidebar()
        toast.error('Event Removed')
      },
      onOk: () => {
        toast.error('Event Not Removed')
      }

    });


  }

  // ** Event Action buttons
  const EventActions = () => {
    if (isObjEmpty(selectedEvent) || (!isObjEmpty(selectedEvent) && !selectedEvent.title.length)) {
      return (
        <Fragment>
          <Button className='me-1' type='submit' color='primary'>
            Add
          </Button>
          <Button color='secondary'  onClick={() => {
            alertConfirm({
              title: 'Confirmation',
              content: 'Are you sure you want cancel? All changes will be lost.',
              onCancel: () => {
                handleAddEventSidebar()
              },
              onOk: () => {

              }
            })
          }} outline>
            Cancel
          </Button>
        </Fragment>
      )
    } else {
      if (!isAll) {
        return (
          <Fragment>
            <Button className='me-1' color='primary' onClick={handleUpdateEvent}>
              Update
            </Button>
            <Button color='danger' onClick={handleDeleteEvent} outline>
              Delete
            </Button>
          </Fragment>
        )
      }
      else {
        return <div></div>
      }
    }
  }

  // ** Close BTN
  const CloseBtn = <X className='cursor-pointer' size={15} onClick={handleAddEventSidebar}/>

  return (
    <Modal
      isOpen={open}
      className='sidebar-lg'
      toggle={handleAddEventSidebar}
      onOpened={handleSelectedEvent}
      onClosed={handleResetInputValues}
      contentClassName='p-0 overflow-hidden'
      modalClassName='modal-slide-in event-sidebar'
    >
      <ModalHeader className='mb-1' toggle={handleAddEventSidebar} close={CloseBtn} tag='div'>
        <h5 className='modal-title'>
          {selectedEvent && selectedEvent.title && selectedEvent.title.length ? 'Update' : 'Add'} Event
        </h5>
      </ModalHeader>
      <PerfectScrollbar options={{wheelPropagation: false}}>
        <ModalBody className='flex-grow-1 pb-sm-0 pb-3'>
          <Form
            onSubmit={handleSubmit(data => {
              if (data.title.length) {
                if (isObjEmpty(errors)) {
                  if (isObjEmpty(selectedEvent) || (!isObjEmpty(selectedEvent) && !selectedEvent.title.length)) {
                    handleAddEvent()
                  } else {
                    handleUpdateEvent()
                  }
                  // handleAddEventSidebar()
                }
              } else {
                setError('title', {
                  type: 'manual'
                })
              }
            })}
          >
            <div className='mb-1'>
              <Label className='form-label' for='title'>
                Employee Name <span className='text-danger'>*</span>
              </Label>
              <Controller
                name='employeeName'
                control={control}
                render={({field}) => (
                  <Input id='employeeName' disabled={true} placeholder='Employee Name'
                         invalid={errors.employeeName && true} {...field} />
                )}
              />
            </div>
            <div className='mb-1'>
              <Label className='form-label' for='title'>
                Title <span className='text-danger'>*</span>
              </Label>
              <Controller
                name='title'
                control={control}
                render={({field}) => (
                  <Input id='title' placeholder='Title' invalid={errors.title && true} {...field} />
                )}
              />
            </div>

            <div className='mb-1'>
              <Label className='form-label' for='label'>
                Label
              </Label>
              <Select
                id='label'
                value={calendarLabel}
                options={options}
                isDisabled={(isObjEmpty(selectedEvent) || (!isObjEmpty(selectedEvent) && !selectedEvent.title.length)) ? false : true}
                theme={selectThemeColors}
                className='react-select'
                classNamePrefix='select'
                isClearable={false}
                onChange={data => setCalendarLabel([data])}
                components={{
                  Option: OptionComponent
                }}
              />
            </div>
            <div className='form-switch mb-1'>
              <Input
                id='allDay'
                type='switch'
                className='me-1'
                checked={allDay}
                name='customSwitch'
                onChange={e => setAllDay(e.target.checked)}
              />
              <Label className='form-label' for='allDay'>
                All Day
              </Label>
            </div>
            <div className='mb-1'>
              <Label className='form-label' for='startDate'>
                Start Date
              </Label>
              <Flatpickr
                required
                id='startDate'
                name='startDate'
                className='form-control'
                onChange={date => setStartPicker(date[0])}
                value={startPicker}
                options={{
                  enableTime: allDay === false,
                  dateFormat: allDay?'Y-m-d':'Y-m-d H:i'
                }}

              />
            </div>


            <div className='mb-1'>
              <Label className='form-label' for='endDate'>
                End Date
              </Label>
              <Flatpickr
                required
                id='endDate'
                // tag={Flatpickr}
                name='endDate'
                className='form-control'
                onChange={date => {
                  setEndPicker(date[0])
                }}
                value={endPicker}
                options={{
                  enableTime: allDay === false,
                  dateFormat: allDay?'Y-m-d':'Y-m-d H:i'
                }}
              />
            </div>


            <div className='mb-1'>
              <Label className='form-label' for='description'>
                Description
              </Label>
              <Input
                type='textarea'
                name='text'
                id='description'
                rows='3'
                value={description}
                onChange={e => setDescription(e.target.value)}
                placeholder='Description'
              />
            </div>
            <div className='d-flex mb-1'>
              <EventActions/>
            </div>
          </Form>
        </ModalBody>
      </PerfectScrollbar>
    </Modal>
  )
}

export default AddEventSidebar
