// ** Redux Imports
import {createSlice, createAsyncThunk} from '@reduxjs/toolkit'
// ** Axios Imports
import axios from 'axios'
import {PUBLIC_API_URL} from "../../../utils";

export const fetchEvents = createAsyncThunk('appCalendar/fetchEvents', async data => {
  const response = await axios.get(`${PUBLIC_API_URL}/api/calendar/${data.company}/${data.employee}`)
  return response.data.data
})

export const addEvent = createAsyncThunk('appCalendar/addEvent', async (event, {dispatch, getState}) => {
  await axios.post(`${PUBLIC_API_URL}/api/calendar`, {...event}, {
    headers: {
      'Content-Type': 'application/json'
    }
  })
  await dispatch(fetchEvents({company: event.company, employee: event.employee}))
  return event
})

export const updateEvent = createAsyncThunk('appCalendar/updateEvent', async (event, {dispatch, getState}) => {
  await axios.put(`${PUBLIC_API_URL}/api/calendar/${event._id}`, {...event}, {
    headers: {
      'Content-Type': 'application/json'
    }
  })
  await dispatch(fetchEvents({company: event.company, employee: event.employee}))
  return event
})

export const updateFilter = createAsyncThunk('appCalendar/updateFilter', async (filter, {dispatch, getState}) => {
  return filter
})

export const updateAllFilters = createAsyncThunk('appCalendar/updateAllFilters', async (value, {dispatch}) => {
  return value
})

export const removeEvent = createAsyncThunk('appCalendar/removeEvent', async( data,{dispatch}) => {
  await axios.delete(`${PUBLIC_API_URL}/api/calendar/${data._id}`)
  await dispatch(fetchEvents({company: data.company, employee: data.employee}))
  return data._id
})

export const appCalendarSlice = createSlice({
  name: 'appCalendar',
  initialState: {
    events: [],
    selectedEvent: {},
    selectedCalendars: ['PO', 'SO', 'callLog','companyExpiry','HR'],
    filteredEvents: null,
  },
  reducers: {
    selectEvent: (state, action) => {
      state.selectedEvent = action.payload
    }
  },
  extraReducers: builder => {
    builder
      .addCase(fetchEvents.fulfilled, (state, action) => {
        state.events = action.payload
      })
      .addCase(updateFilter.fulfilled, (state, action) => {
        if (state.selectedCalendars.includes(action.payload)) {
          state.selectedCalendars.splice(state.selectedCalendars.indexOf(action.payload), 1)
        } else {
          state.selectedCalendars.push(action.payload)
        }
      //  filter events if there are any selected calendars in the field of event.extendedProps.calendar
      state.filteredEvents = state.events.filter(event => state.selectedCalendars.includes(event.extendedProps.calendar))
      })
      .addCase(updateAllFilters.fulfilled, (state, action) => {
        const value = action.payload
        if (value === true) {
          state.selectedCalendars = ['PO', 'SO', 'callLog','companyExpiry','HR']
          state.filteredEvents = state.events
        } else {
          state.selectedCalendars = []
          state.filteredEvents = []
        }
      })
  }
})

export const {selectEvent} = appCalendarSlice.actions

export default appCalendarSlice.reducer
