// ** React Imports
import {Fragment, useState, useEffect} from 'react'

// ** Third Party Components
import classnames from 'classnames'
import {Row, Col, AccordionHeader, ListGroupItemHeading, Button, Card} from 'reactstrap'

// ** Calendar App Component Imports
import Calendar from './Calendar'
import SidebarLeft from './SidebarLeft'
import AddEventSidebar from './AddEventSidebar'

// ** Custom Hooks

// ** Store & Actions
import {useSelector, useDispatch} from 'react-redux'
import {fetchEvents, selectEvent, updateEvent, updateFilter, updateAllFilters, addEvent, removeEvent} from './store'

// ** Styles
import '../../scss/react/apps/app-calendar.scss'
import toast from "react-hot-toast";

// ** CalendarColors
const calendarsColor = {
  PO: 'danger',
  SO: 'success',
  callLog: 'warning',
  companyExpiry: 'info',
  HR: 'secondary',
}

const CalendarComponent = ({company, employee, name, hourSlot, isAll}) => {
  // ** Variables
  const dispatch = useDispatch()
  const store = useSelector(state => state.calendar)

  // ** states
  const [calendarApi, setCalendarApi] = useState(null)
  const [addSidebarOpen, setAddSidebarOpen] = useState(false)
  const [leftSidebarOpen, setLeftSidebarOpen] = useState(false)

  // ** AddEventSidebar Toggle Function
  const handleAddEventSidebar = () => setAddSidebarOpen(!addSidebarOpen)

  // ** LeftSidebar Toggle Function
  const toggleSidebar = val => setLeftSidebarOpen(val)

  // ** Blank Event Object
  const blankEvent = {
    title: '',
    start: '',
    end: '',
    allDay: false,
    url: '',
    extendedProps: {
      calendar: '',
      guests: [],
      location: '',
      description: ''
    }
  }

  // ** refetchEvents
  const refetchEvents = () => {
    if (calendarApi !== null) {
      calendarApi.refetchEvents()
    }
  }
  const FetchEvents = () => {
    dispatch(fetchEvents({company, employee}))
  }
  // ** Fetch Events On Mount
  useEffect(() => {
    FetchEvents()
  }, [])

  return (
    <Fragment>
      {hourSlot && <Row>
        <Col sm={12}>
      <ListGroupItemHeading className={'m-auto'} style={{
        fontSize: "40px",
        display: "flex",
        alignItems: "center",
        justifyContent: "center"
      }}>My Calendar


      </ListGroupItemHeading>
        </Col>
        <Card className={'m-auto'} style={{width: "200px"}}>
          <Button color='primary'  onClick={() => {
            dispatch(fetchEvents({company, employee}))
          }}
          style={{width:'200px'}}>
            Refresh my calendar
          </Button>
        </Card>
      </Row>
      }
      <div className='app-calendar overflow-hidden border'>
        <Row className='g-0'>
          {!hourSlot &&
            <>
              <Col
                id='app-calendar-sidebar'
                className={classnames('col app-calendar-sidebar flex-grow-0 overflow-hidden d-flex flex-column', {
                  show: leftSidebarOpen
                })}
              >
                <SidebarLeft
                  store={store}
                  dispatch={dispatch}
                  updateFilter={updateFilter}
                  toggleSidebar={toggleSidebar}
                  updateAllFilters={updateAllFilters}
                  handleAddEventSidebar={handleAddEventSidebar}
                  fetchEvents={FetchEvents}
                />
              </Col>
            </>
          }
          <Col className='position-relative'>
            <Calendar
              store={store}
              dispatch={dispatch}
              blankEvent={blankEvent}
              calendarApi={calendarApi}
              hourSlot={hourSlot}
              selectEvent={selectEvent}
              isAll={isAll}

              updateEvent={updateEvent}
              toggleSidebar={toggleSidebar}
              calendarsColor={calendarsColor}
              setCalendarApi={setCalendarApi}
              handleAddEventSidebar={handleAddEventSidebar}
            />
          </Col>
          <div
            className={classnames('body-content-overlay', {
              show: leftSidebarOpen === true
            })}
            onClick={() => toggleSidebar(false)}
          ></div>
        </Row>
      </div>

        <AddEventSidebar
          store={store}
          dispatch={dispatch}
          addEvent={addEvent}
          employeeName={name}
          open={addSidebarOpen}
          selectEvent={selectEvent}
          updateEvent={updateEvent}
          removeEvent={removeEvent}
          calendarApi={calendarApi}
          refetchEvents={refetchEvents}
          company={company}
          employee={employee}
          calendarsColor={calendarsColor}
          handleAddEventSidebar={handleAddEventSidebar}
          isAll={isAll}

        />

    </Fragment>
  )
}

export default CalendarComponent
