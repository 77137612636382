// ** React Imports
import { Fragment } from 'react'

// ** Custom Components
import classnames from 'classnames'

// ** Reactstrap Imports
import { CardBody, Button, Input, Label } from 'reactstrap'
import toast from "react-hot-toast";

// ** illustration import

// ** Filters Checkbox Array
const filters = [
  { label: 'PO',value:'PO', color: 'danger', className: 'form-check-danger mb-1' },
  { label: 'SO',value:'SO', color: 'success', className: 'form-check-success mb-1' },
  { label: 'Call Log',value:'callLog', color: 'warning', className: 'form-check-warning mb-1' },
  { label: 'Company Expiry',value:'companyExpiry', color: 'info', className: 'form-check-info mb-1' },
  { label: 'HR',value:'HR', color: 'secondary', className: 'form-check-secondary mb-1' },
]

const SidebarLeft = props => {
  // ** Props
  const { handleAddEventSidebar, toggleSidebar, updateFilter, updateAllFilters, store, dispatch ,fetchEvents} = props

  // ** Function to handle Add Event Click
  const handleAddEventClick = () => {
    toggleSidebar(false)
    handleAddEventSidebar()
  }

  return (
    <Fragment>
      <div className='sidebar-wrapper' style={{padding:"10px 30px"}}>
        <CardBody className='card-body d-flex justify-content-center my-sm-0 mb-3'>
          <Button color='primary' block onClick={()=>{
            fetchEvents()
            toast.success('Calendar Refreshed')
          }}>
            <span className='align-middle'>Refresh My Calendar</span>
          </Button>
        </CardBody>

        <CardBody>
          <h5 className='section-label mb-1'>
            <span className='align-middle'>Filter</span>
          </h5>
          <div className='form-check mb-1'>
            <Input
              id='view-all'
              type='checkbox'
              label='View All'
              className='select-all'
              checked={store.selectedCalendars.length === filters.length}
              onChange={e => dispatch(updateAllFilters(e.target.checked))}
            />
            <Label className='form-check-label' for='view-all'>
              View All
            </Label>
          </div>
          <div className='calendar-events-filter'>
            {filters.length &&
              filters.map(filter => {
                return (
                  <div
                    key={`${filter.label}-key`}
                    className={classnames('form-check', {
                      [filter.className]: filter.className
                    })}
                  >
                    <Input
                      type='checkbox'
                      key={filter.value}
                      label={filter.value}
                      className='input-filter'
                      id={`${filter.value}-event`}
                      checked={store.selectedCalendars.includes(filter.value)}
                      onChange={() => {
                        dispatch(updateFilter(filter.value))
                      }}
                    />
                    <Label className='form-check-label' for={`${filter.value}-event`}>
                      {filter.label}
                    </Label>
                  </div>
                )
              })}
          </div>
        </CardBody>
      </div>

    </Fragment>
  )
}

export default SidebarLeft
