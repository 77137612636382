import React from 'react';
import Calendar from "../components/calendar";
import {useParams,useLocation} from 'react-router-dom';

const Home = () => {
  //get slug from url using react-router
  const {companyID, userID,name} = useParams();
  let hourSlot = useLocation().search;
  hourSlot = hourSlot.split("=")[1];
  if(hourSlot === undefined || hourSlot === "" || hourSlot === "false"|| !hourSlot){
    hourSlot = false;
  }
  else{
    hourSlot = true;
  }



  return (
    <Calendar company={companyID} employee={userID} name={name} isAll={userID==='all-employees'} hourSlot={hourSlot}/>
  );
};

export default Home;
