// ** React Import
import {memo, useEffect, useRef, useState} from 'react'

// ** Full Calendar & it's Plugins
import FullCalendar from '@fullcalendar/react'
import listPlugin from '@fullcalendar/list'
import dayGridPlugin from '@fullcalendar/daygrid'
import timeGridPlugin from '@fullcalendar/timegrid'
import interactionPlugin from '@fullcalendar/interaction'

// ** Third Party Components
import toast from 'react-hot-toast'
import {Menu} from 'react-feather'
import {Card, CardBody} from 'reactstrap'

const Calendar = props => {
  // ** Refs
  const calendarRef = useRef(null)
  const [slotDuration, setSlotDuration] = useState('00:30:00')
  //set scroll time to current hour

  const scrollTime = new Date().getHours() + ':00:00'
  // ** Props
  const {
    store,
    isRtl,
    dispatch,
    calendarsColor,
    calendarApi,
    setCalendarApi,
    handleAddEventSidebar,
    blankEvent,
    toggleSidebar,
    selectEvent,
    isAll,
    updateEvent, hourSlot
  } = props

  // ** UseEffect checks for CalendarAPI Update
  useEffect(() => {
    if (calendarApi === null) {
      setCalendarApi(calendarRef.current.getApi())
    }
  }, [calendarApi])

  // ** calendarOptions(Props)
  const calendarOptions = {
    events: store.filteredEvents ? store.filteredEvents : store.events,
    plugins: [interactionPlugin, dayGridPlugin, timeGridPlugin, listPlugin],
    initialView: 'timeGridDay',
    headerToolbar: {
      start: 'sidebarToggle, prev,next, title',
      center: 'fifteen , thirty, oneHour',
      end: 'dayGridMonth,timeGridWeek,timeGridDay,listMonth'
    },
    /*
      Enable dragging and resizing event
      ? Docs: https://fullcalendar.io/docs/editable
    */
    editable: true,
    eventContent: function (event) {
      const text = isAll ? ' - (' + event.event._def.extendedProps.employeeName + ')' : ''
      return event.timeText + ' - ' + event.event._def.title + text;
    },

    /*
      Enable resizing event from start
      ? Docs: https://fullcalendar.io/docs/eventResizableFromStart
    */
    eventResizableFromStart: true,

    /*
      Automatically scroll the scroll-containers during event drag-and-drop and date selecting
      ? Docs: https://fullcalendar.io/docs/dragScroll
    */
    dragScroll: true,

    /*
      Max number of events within a given day
      ? Docs: https://fullcalendar.io/docs/dayMaxEvents
    */
    dayMaxEvents: 100,
    /*
      Determines if day names and week names are clickable
      ? Docs: https://fullcalendar.io/docs/navLinks
    */
    navLinks: true,

    eventClassNames({event: calendarEvent}) {
      // eslint-disable-next-line no-underscore-dangle
      const colorName = calendarsColor[calendarEvent._def.extendedProps.calendar]

      return [
        // Background Color
        `bg-light-${colorName}`
      ]
    },


    eventClick({event: clickedEvent}) {
      dispatch(selectEvent(clickedEvent))
      handleAddEventSidebar()

      // * Only grab required field otherwise it goes in infinity loop
      // ! Always grab all fields rendered by form (even if it get `undefined`) otherwise due to Vue3/Composition API you might get: "object is not extensible"
      // event.value = grabEventDataFromEventApi(clickedEvent)

      // eslint-disable-next-line no-use-before-define
      // isAddNewEventSidebarActive.value = true
    },

    customButtons: {
      sidebarToggle: {
        text: <Menu className='d-xl-none d-block'/>,
        click() {
          toggleSidebar(true)
        }
      },
      fifteen: {
        text: '15 Mins',
        click: function() {

          if(slotDuration !== '00:15:00') {
            setSlotDuration('00:15:00')
            toast.success('Slot Duration Changed to 15 Mins')
          }
        }
      },
      thirty: {
        text: '30 Mins',
        click: function() {
          if(slotDuration !== '00:30:00') {
            setSlotDuration('00:30:00')
            toast.success('Slot Duration Changed to 30 Mins')
          }

        }
      },
      oneHour: {
        text: '1 Hour',
        click: function() {
          if (slotDuration !== '01:00:00') {
            setSlotDuration('01:00:00')
            toast.success('Slot Duration Changed to 1 Hour')
          }
        }
      }
    },

    dateClick(info) {
      const ev = blankEvent
      ev.start = info.date
      ev.end = info.date
      dispatch(selectEvent(ev))
      handleAddEventSidebar()
    },

    /*
      Handle event drop (Also include dragged event)
      ? Docs: https://fullcalendar.io/docs/eventDrop
      ? We can use `eventDragStop` but it doesn't return updated event so we have to use `eventDrop` which returns updated event
    */
    eventDrop({event: droppedEvent}) {
      dispatch(updateEvent(droppedEvent))
      toast.success('Event Updated')
    },

    /*
      Handle event resize
      ? Docs: https://fullcalendar.io/docs/eventResize
    */
    eventResize({event: resizedEvent}) {
      dispatch(updateEvent(resizedEvent))
    },

    ref: calendarRef,

    // Get direction from app state (store)
    direction: isRtl ? 'rtl' : 'ltr',

  }

  return (
    <Card className='shadow-none border-0 mb-0 rounded-0'>
      <CardBody className='pb-0'>
        <FullCalendar {...calendarOptions} nowIndicator={true} firstDay={1} scrollTime={"08:00:00"} eventStartEditable={false}
                      slotEventOverlap={false}
                      slotDuration={slotDuration} dragScroll={false}/>{' '}
      </CardBody>
    </Card>
  )
}

export default memo(Calendar)
