import './scss/core.scss'
import './scss/style.scss'
import Routes from "./Routes";
import { store } from './redux/store'
import { Provider } from 'react-redux'
import { Toaster } from 'react-hot-toast';

function App() {
  return (

    <>
      <Provider store={store}>
        <Toaster/>
        <Routes />
      </Provider>
    </>
  );
}

export default App;
