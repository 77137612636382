import { Route, Routes as BaseRoutes } from "react-router-dom";
import Home from "./pages/Home";
import DayEvents from "./DayEvents";

export default function Routes() {
  return (
    <BaseRoutes>
      <Route path="/" element={<div>Please contact dds</div>} />
      <Route path=":companyID">
        <Route path=":userID" element={<DayEvents />} />
      </Route>

      <Route path=":companyID">
        <Route path=":userID">
        <Route path=":name" element={<Home />} />
        </Route>
      </Route>
    </BaseRoutes>
  );
}
